/* CreateContactForm.css */
.create-contact-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .form {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .form-col {
    flex: 1;
    margin-right: 20px;
  }
  
  .form-col:last-child {
    margin-right: 0;
  }
  
  .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .required {
    color: red;
    margin-left: 5px;
  }
  
  .form-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .submit-contact-button,
  .cancel-button {
    margin-left: 10px;
    margin-right: 35px;
    background-color: #0b6e4f;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-contact-button:hover,
  .cancel-button:hover {
    background-color: #11372b;
  }
