/* .form {
    position: relative;
} */

.formRow {
    margin: 0 !important;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

.formRow2 {
    margin: 0 !important;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

.formRow3 {
    margin: 0 !important;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

.formRow5 {
    width: 97%;
    justify-content: space-between;
    padding-right: 12px;
    padding-left: 12px;
}

.formRow6 {
    width: 97%;
    justify-content: space-between;
    padding-right: 12px;
    padding-left: 12px; 
}


.formCol {
    padding-left: 12px !important;
    padding-right: 12px !important;
    flex: 0 0 25%;
    max-width: 25%;
    position: relative;
    min-height: 1px;
    box-sizing: border-box;
}

.formCol2 {
    padding-left: 12px !important;
    padding-right: 12px !important;
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    min-height: 1px;
    box-sizing: border-box;
}

.formCol3 {
    padding-left: 12px !important;
    padding-right: 12px !important;
    flex-direction: 0 0 25%;
    max-width: 25%;
    position: relative;
    min-height: 1px;
    box-sizing: border-box;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

.formRow5 {
    margin: 0 !important;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    padding-right: 12px;
    padding-left: 12px;
}
  
  .formRow5 .formCol3 {
    flex: 1;
  }

.formColLegal {
    padding-left: 12px !important;
    padding-right: 12px !important;
    flex: 0 0 50%; /* This is correct for taking up half the row */
    max-width: 50%;
    position: relative;
    min-height: 1px;
    box-sizing: border-box;
}

.formCol6 {
    /* padding: 0; */
    /* padding-left: 12px !important; */
    /* padding-right: 12px !important; */
    /* position: relative; */
    /* width: 100%; */
    /* min-height: 1px; */
}

.formLabel {
    margin-bottom: 0;
    font-size: 14px;
    color: #8B9291;
    font-weight: 400;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.formLabel2 {
    margin-bottom: 0;
    font-size: 14px;
    color: #8B9291;
    font-weight: 400;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.formLabel3 {
    margin-bottom: 0;
    font-size: 14px;
    color: #8B9291;
    font-weight: 400;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.formLabelLegal {
    margin-bottom: 0;
    font-size: 14px;
    color: #8B9291;
    font-weight: 400;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.formLabel5 {
    margin-bottom: 0;
    font-size: 14px;
    color: #8B9291;
    font-weight: 400;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.formLabel6 {
    margin-bottom: 0;
    font-size: 14px;
    color: #8B9291;
    font-weight: 400;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.formSpan {
    position: relative;
    box-sizing: border-box;
    font-size: 14px;
}

.formInput {
    width: 100%;
    height: 40px;
    background-color: transparent;
    padding: 0 18px 0 11px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #B9BEBD;
    outline: none;
    margin-bottom: 16px;
}

.formInput2 {
    box-sizing: border-box;
    box-shadow: none;
    overflow: visible;
    margin: 0;
    font-family: inherit;
    line-height: inherit;
}

.formInput3 {
    width: 100%;
    height: 40px;
    background-color: transparent;
    padding: 0 18px 0 11px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #B9BEBD;
    outline: none;
    margin-bottom: 16px;
}

.formInputLegal {
    width: 100%;
    height: 40px;
    background-color: transparent;
    padding: 0 18px 0 11px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #B9BEBD;
    outline: none;
    margin-bottom: 16px;
}

.formInput5 {
    width: 100%;
    height: 40px;
    background-color: transparent;
    padding: 0 18px 0 11px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #B9BEBD;
    outline: none;
    margin-bottom: 16px;
    box-sizing: border-box;
}

.formInput6 {
    width: 100%;
    height: 40px;
    background-color: transparent;
    padding: 0 18px 0 11px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #B9BEBD;
    outline: none;
    margin-bottom: 16px;
    box-sizing: border-box;
}

.formFooter {
    display: flex;
    justify-content: flex-end;
    padding: 12px;
    margin-top: 20px;
}

.editButton {
    width: 98px;
    padding: 0 12px;
    height: 36px;
    border-radius: 4px;
    background-color: #0b6e4f; /* Standard green background */
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    outline: none !important;
    float: right;
    border: none;
    cursor: pointer;
}

.editButton.hover:hover {
    background-color: #11372b; /* Darker shade for hover effect, adjust as needed */
}


.required {
    color: red;
    margin-left: 5px;
  }
  
