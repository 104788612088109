.btn {
  background-color: #0b6e4f;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn.create-dwr-button {
  margin-left: auto;
}

.btn:hover, .btn.create-dwr-button:hover {
  background-color: #11372b;
}

.work-description-cell {
  width: 56%;
  min-width: 400px;
  white-space: normal; /* Allow wrapping */
  word-break: break-word;
  overflow-wrap: break-word;
}


.from-time-cell, .to-time-cell {
width: 17%;
min-width: 100px;
}

.delete-cell {
width: 10%;
min-width: 80px;
}

.input-container {
display: flex;
align-items: flex-start;
}

.custom-text-field {
width: 40% !important;
}

.custom-text-field .MuiInputBase-inputMultiline,
.custom-text-field .MuiOutlinedInput-inputMultiline {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  /* Removing any fixed width settings here */
}

.w-full.overflow-x-auto table {
  width: 100%;
  table-layout: fixed;
}
