.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5; /* Assuming there's a light background */
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px; /* Add padding around the form */
    background: #ffffff; /* White background for the form */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow around the form */
    border-radius: 8px; /* Rounded corners for the form */
    width: 300px; /* Fixed width, adjust as needed */
    gap: 20px; /* Adjust the spacing between elements */
}

.logo {
    width: 200px; /* Adjust as needed based on the actual logo size */
    height: auto; /* Maintain aspect ratio of the logo */
    margin-bottom: 20px; /* Space below the logo */
}

input {
    width: 100%; /* Full width of the form */
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* Include padding in the width calculation */
}

button {
    width: 100%; /* Full width of the form */
    padding: 10px 16px; /* Adjust padding as needed */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    background-color: #0b6e4f; /* Standard green background */
    color: white; /* Text color */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

button:disabled {
    background-color: #ccc;
}

button:hover:not(:disabled) {
    background-color: #0056b3; /* Darker blue on hover */
}
