/* Custom DataGrid styling for the WellRows component */
.DataGrid-container {
    height: 90vh; 
    width: '100%';
    background-color: #f5f5f5; /* Light background for the DataGrid area */
}

.MuiDataGrid-columnHeaders {
    background-color: #0b6e4f; /* Standard green background for column headers */
    color: white; /* White text for header names */
    font-weight: bold; /* Make header names stand out */
}

.MuiDataGrid-columnHeaderTitle {
    color: white; /* Ensures column titles are white, enhancing readability */
}

.MuiDataGrid-cell {
    color: #333; /* Darker text for better contrast and readability */
    border-bottom: 1px solid #e0e0e0; /* Light gray border for each cell */
}

.MuiDataGrid-row:hover {
    background-color: #e9f5f3; /* Very light green background for row hover */
}

.MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected:hover {
    background-color: #11372b; /* Darker green when a row is selected */
    color: white; /* White text for selected row */
}

.MuiDataGrid-footerContainer {
    background-color: #f5f5f5; /* Footer background to match the DataGrid's main area */
}


/* Style for the sorting icon */
.MuiDataGrid-sortIcon {
    color: white !important; /* Set sorting icon to white */
}

/* Style for column selector icon */
.MuiDataGrid-menuIconButton {
    color: white !important; /* Set the column selector icon to white */
}

.MuiDataGrid-sortIcon {
    color: white !important; /* Set sorting icon to white */
}

/* Hover style for the header cell that contains the sorting icon */
.MuiDataGrid-columnHeader:hover .MuiDataGrid-sortIcon {
    background-color: transparent; /* Light grey background on hover for sorting icon */
}

/* Style for column selector icon */
.MuiDataGrid-menuIconButton {
    color: white !important; /* Set the column selector icon to white */
}

/* Hover style for the header cell that contains the column selector icon */
.MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIconButton {
    background-color: transparent /* Light grey background on hover for column selector icon */
}

.notes-cell {
    white-space: normal;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
