.sis-form-container {
    padding-left: 12px !important;
    padding-right: 12px !important;
    flex: 0 0 100%;
    max-width: none;
    position: relative;
    width: 100%;
    min-height: 1px;
}

.sis-button-group {
    display: block;
    margin-left: 5px;
    margin-right: 40px;
    text-align: left;
}

.btn {
    margin-left: 10px;
    margin-right: 35px;
    background-color: #0b6e4f; /* Standard green background */
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px; /* Consistent font size */
    display: inline-block; /* Ensures buttons align properly */
    white-space: nowrap; /* Prevents text wrapping */
    text-align: center;
    vertical-align: middle; /* Align text vertically in the button */
    line-height: normal; /* Ensures proper text line-height */
}

.btn.hover:hover {
    background-color: #0d3428; /* Darker shade for hover effect */
    color: white;
}

.btn.create-dwr-button {
    margin-left: 10px;
    margin-right: 35px;
}

.btn:hover {
    background-color: #11372b;
    color: white;
}

.clickable-link {
    cursor: pointer;
    text-decoration: underline;
    color: blue;
    transition: background-color 0.3s;
}

.clickable-link.loading {
    cursor: wait;
    opacity: 0.5;
}

.clickable-link:hover {
    background-color: #e0e0e0;
}

.table-container {
    margin: 10px;
    width: calc(100% - 20px);
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    text-align: left;
    padding: 8px;
    border: 1px solid #ddd;
    word-wrap: break-word;
    width: 16.66%; /* Evenly divides width across 6 columns */
}

button {
    white-space: nowrap;
    padding: 8px 16px; /* Consistent button padding */
    font-size: 14px; /* Consistent button font size */
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #0b6e4f;
    color: white;
}

button:hover {
    background-color: #11372b;
    color: white;
}
