.list-view {
    height: 70px;
    padding-top: 0;
    border-bottom: 1px solid #E7E9E9;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    z-index: 1000;
    background-color: #FFFFFF;
    top: 0;
}

.list-header {
    margin-left: 71px;
    transition: 0.3s;
    font-weight: 700;
    font-size: 24px;
}

.avatar {
    cursor: pointer;
    margin-right: 20px;
}

.list-tab {
    display: flex;
    list-style-type: none;
    padding: 0;
    align-items: center;
    width: 100%;
}

.list-tab li {
    margin-right: 10px;
    cursor: pointer;
}

.list-tab li.active {
    font-weight: bold;
}

.btn {
    margin-left: 10px;
    background-color: #0b6e4f;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn.create-dwr-button {
    margin-left: auto;
}

.btn:hover, .btn.create-dwr-button:hover {
    background-color: #11372b;
}

.file-upload {
    margin-top: 10px;
}

.file-upload input {
    margin-right: 10px;
}

.file-upload button {
    background-color: #0b6e4f;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.file-manage {
    margin-top: 20px;
}

.file-manage button {
    background-color: #0b6e4f;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 10px;
}

.file-manage button:nth-child(2) {
    background-color: #ff0000;
    padding: 5px;
    margin-top: 10px;

}

.tab-content ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
}

.tab-content ul li {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #e7e9e9;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.tab-content ul li input[type="checkbox"] {
    margin-right: 10px;
}

.tab-content ul li a {
    text-decoration: none;
    color: #007bff;
    margin-right: 10px;
}

.tab-content ul li span {
    color: #555;
}

.tab-content h2 {
    margin-top: 0px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.tab-content {
    padding: 20px;
    margin-top: 0px; /* to account for the fixed header */
}
