
.list-view {
    height: 90px;
    padding-top: 5px;
    margin-left: 5px;
    border-bottom: 1px solid #E7E9E9;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    z-index: 1000;
    background-color: #FFFFFF;
    top: 0;
  }
  
  .list-header {
    margin-left: 71px;
    transition: 0.3s;
    font-weight: 700;
    font-size: 24px;
  }
  
  .sidebar {
    position: fixed;
    right: 0;
    top: 0;
    width: 250px;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0,0,0,0.5);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .sidebar.open {
    transform: translateX(0);
  }
  
  .avatar {
    cursor: pointer;
    margin-right: 20px;
  }
  
  .items {
    box-sizing: border-box;
    font-size: 15px;
    padding-top: 0px;
  }
  
  .list-tab {
    display: flex;
    align-items: center;
    padding: 10px 0 9px 46px;
    border-bottom: 1px solid #EBEBEB;
    margin-bottom: 24px;
    list-style: none;
  }
  
  .list-tab li {
    cursor: pointer;
    margin-right: 30px;
    padding: 10px 20px;
    position: relative;
    font-weight: 700;
    transition: background-color 0.3s;
  }
  
  .list-tab li.active {
    /* background-color: #f0f0f0; */
  }
  
  .list-tab li::after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 0%;
    height: 3px;
    background: transparent;
    transition: width 0.3s ease, background-color 0.3s ease;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .list-tab li.active::after,
  .list-tab li:hover::after {
    width: 100%;
    background-color: #1D6CE8;
  }
  
  .create-well-tab {
    margin-left: auto;
    padding: 10px 20px;
  }
  
  .create-well-button {
    padding: 8px 16px;
    background-color: #0b6e4f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .create-well-button:hover {
    background-color: #11372b;
  }
  
  .add-new-item {
    color: #0055a4;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
  }
  
  .add-new-item:hover {
    background-color: transparent;
    text-decoration: underline;
  }
  
  .add-new-item:focus {
    background-color: transparent;
    border: none;
    outline: none;
  }
  
  .add-icon {
    font-size: 1.2em;
  }
