.dwrFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.date-and-ticket-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.date-wrapper,
.ticket-number-wrapper,
.contact-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
}

.date-wrapper label,
.contact-wrapper label {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.ticket-number-wrapper {
    justify-content: center;
}
.day-wrapper{
    flex: 1;
    display: flex;
    align-items: center;
}

.date-wrapper input,
.contact-wrapper select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
}

.well-form-wrapper {
    width: 100%; 
    margin-bottom: 20px;
}

.submit-dwr-button {
    margin-left: 10px;
    margin-right: 35px;
    background-color: #0b6e4f;
    color: white; 
    padding: 8px 16px; 
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-dwr-button:hover {
    background-color: #11372b;
}

.btn {
    margin-left: 10px;
    margin-right: 35px;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: #0b6e4f;
    color: white;
    padding: 8px 16px; 
    border: none; 
    border-radius: 5px;
    cursor: pointer;
}

.btn.create-dwr-button {
    margin-left: auto; 
}


.btn:hover, .btn.create-dwr-button:hover {
    background-color: #11372b; 
}


.last-day-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.last-day-wrapper .MuiFormControlLabel-root {
margin-right: 0;
}
